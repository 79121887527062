import { AccountType } from "@/enums/AccountType";
import IDocumentProperties from "@/interfaces/IDocumentProperties";
import { DocumentCategory } from "@/enums/DocumentCategory";
import { DocumentType } from "@/enums/DocumentType";

export default class DocumentProperties {
  private static INSTANCE: DocumentProperties | null;

  static getInstance(): DocumentProperties {
    if (!DocumentProperties.INSTANCE) {
      DocumentProperties.INSTANCE = new DocumentProperties();
    }

    return DocumentProperties.INSTANCE;
  }

  getListOfDocumentProperties(): IDocumentProperties[] {
    return [
      {
        type: DocumentType.ADVIES,
        category: DocumentCategory.OTHER,
        accountType: [AccountType.COMPANY, AccountType.PERSON],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR"],
      },
      {
        type: DocumentType.OFFERTE,
        category: DocumentCategory.OTHER,
        accountType: [AccountType.COMPANY, AccountType.PERSON],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR"],
      },
      {
        type: DocumentType.OPDRACHTBEVESTIGING,
        category: DocumentCategory.OTHER,
        accountType: [AccountType.COMPANY, AccountType.PERSON],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR"],
      },
      {
        type: DocumentType.OVEREENKOMST_OVERIG,
        category: DocumentCategory.OTHER,
        accountType: [AccountType.COMPANY, AccountType.PERSON],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR"],
      },
      {
        type: DocumentType.LOR,
        category: DocumentCategory.FINANCIAL,
        accountType: [AccountType.COMPANY],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR", "FINANCIAL_YEAR", "CUSTOM"],
      },
      {
        type: DocumentType.CONCEPT_JAARREKENING,
        category: DocumentCategory.FINANCIAL,
        accountType: [AccountType.COMPANY],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR", "FINANCIAL_YEAR", "CUSTOM"],
      },
      {
        type: DocumentType.NOTULEN,
        category: DocumentCategory.FINANCIAL,
        accountType: [AccountType.COMPANY],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR"],
      },
      {
        type: DocumentType.CONTROLEVERKLARING,
        category: DocumentCategory.FINANCIAL,
        accountType: [AccountType.COMPANY],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR", "FINANCIAL_YEAR", "CUSTOM"],
      },
      {
        type: DocumentType.VERKLARING_PENSIOEN_EN_STAMRECHT,
        category: DocumentCategory.FINANCIAL,
        accountType: [AccountType.COMPANY],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR"],
      },
      {
        type: DocumentType.BEZWAARSCHRIFT,
        category: DocumentCategory.FISCAL,
        accountType: [AccountType.COMPANY, AccountType.PERSON],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR"],
      },
      {
        type: DocumentType.DIVIDENDBELASTING,
        category: DocumentCategory.FISCAL,
        accountType: [AccountType.COMPANY],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR"],
      },
      {
        type: DocumentType.BRIEF,
        category: DocumentCategory.OTHER,
        accountType: [AccountType.COMPANY, AccountType.PERSON],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR"],
      },
      {
        type: DocumentType.AKKOORD_MJL,
        category: DocumentCategory.OTHER,
        accountType: [AccountType.COMPANY, AccountType.PERSON],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR"],
      },
      {
        type: DocumentType.ONDERZOEKSRAPPORT_PROGNOSE,
        category: DocumentCategory.FINANCIAL,
        accountType: [AccountType.COMPANY],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR"],
      },
      {
        type: DocumentType.VENNOOTSCHAPSBELASTING,
        category: DocumentCategory.FISCAL,
        accountType: [AccountType.COMPANY],
        defaultExpiresInDays: 0,
        period: ["CALENDAR_YEAR", "FINANCIAL_YEAR"],
      },
      {
        type: DocumentType.VERWERKERSOVEREENKOMST,
        category: DocumentCategory.OTHER,
        accountType: [AccountType.COMPANY, AccountType.PERSON],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR"],
      },
      {
        type: DocumentType.BEOORDELINGSVERKLARING,
        category: DocumentCategory.FINANCIAL,
        accountType: [AccountType.COMPANY],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR"],
      },
      {
        type: DocumentType.SAMENSTELLINGSVERKLARING,
        category: DocumentCategory.FINANCIAL,
        accountType: [AccountType.COMPANY],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR", "FINANCIAL_YEAR", "CUSTOM"],
      },
      {
        type: DocumentType.INKOMSTENBELASTING,
        category: DocumentCategory.FISCAL,
        accountType: [AccountType.PERSON],
        defaultExpiresInDays: 0,
        period: ["CALENDAR_YEAR", "FINANCIAL_YEAR"],
      },
      {
        type: DocumentType.KREDIETRAPPORTAGE_BEPERKT,
        category: DocumentCategory.FINANCIAL,
        accountType: [AccountType.COMPANY],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR", "FINANCIAL_YEAR", "CUSTOM"],
      },
      {
        type: DocumentType.KREDIETRAPPORTAGE_MIDDELGROOT,
        category: DocumentCategory.FINANCIAL,
        accountType: [AccountType.COMPANY],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR", "FINANCIAL_YEAR", "CUSTOM"],
      },
      {
        type: DocumentType.KREDIETRAPPORTAGE_PERSOON,
        category: DocumentCategory.FINANCIAL,
        accountType: [AccountType.COMPANY],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR", "FINANCIAL_YEAR", "CUSTOM"],
      },
      {
        type: DocumentType.OMZETBELASTING,
        category: DocumentCategory.FISCAL,
        accountType: [AccountType.COMPANY, AccountType.PERSON],
        defaultExpiresInDays: 30,
        period: [
          "MONTH",
          "QUARTER",
          "QUARTER_FINANCIAL_YEAR",
          "CALENDAR_YEAR",
          "FINANCIAL_YEAR",
        ],
      },
      {
        type: DocumentType.OMZETBELASTING_SUPPLETIE,
        category: DocumentCategory.FISCAL,
        accountType: [AccountType.COMPANY, AccountType.PERSON],
        defaultExpiresInDays: 30,
        period: [
          "MONTH",
          "QUARTER",
          "QUARTER_FINANCIAL_YEAR",
          "CALENDAR_YEAR",
          "FINANCIAL_YEAR",
        ],
      },
      {
        type: DocumentType.ICP,
        category: DocumentCategory.FISCAL,
        accountType: [AccountType.COMPANY, AccountType.PERSON],
        defaultExpiresInDays: 30,
        period: ["MONTH", "BIMONTHLY", "QUARTER", "QUARTER_FINANCIAL_YEAR"],
      },
      {
        type: DocumentType.JAARREKENING_KLEIN,
        category: DocumentCategory.FINANCIAL,
        accountType: [AccountType.COMPANY],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR", "FINANCIAL_YEAR", "CUSTOM"],
      },
      {
        type: DocumentType.JAARREKENING_MICRO,
        category: DocumentCategory.FINANCIAL,
        accountType: [AccountType.COMPANY],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR", "FINANCIAL_YEAR", "CUSTOM"],
      },
      {
        type: DocumentType.JAARREKENING_MIDDELGROOT,
        category: DocumentCategory.FINANCIAL,
        accountType: [AccountType.COMPANY],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR", "FINANCIAL_YEAR", "CUSTOM"],
      },
      {
        type: DocumentType.JAARREKENING_GROOT,
        category: DocumentCategory.FINANCIAL,
        accountType: [AccountType.COMPANY],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR", "FINANCIAL_YEAR", "CUSTOM"],
      },
      {
        type: DocumentType.INRICHTINGSJAARREKENING_KLEIN,
        category: DocumentCategory.FINANCIAL,
        accountType: [AccountType.COMPANY],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR", "FINANCIAL_YEAR", "CUSTOM"],
      },
      {
        type: DocumentType.INRICHTINGSJAARREKENING_MICRO,
        category: DocumentCategory.FINANCIAL,
        accountType: [AccountType.COMPANY],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR", "FINANCIAL_YEAR", "CUSTOM"],
      },
      {
        type: DocumentType.INRICHTINGSJAARREKENING_MIDDELGROOT,
        category: DocumentCategory.FINANCIAL,
        accountType: [AccountType.COMPANY],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR", "FINANCIAL_YEAR", "CUSTOM"],
      },
      {
        type: DocumentType.INRICHTINGSJAARREKENING_GROOT,
        category: DocumentCategory.FINANCIAL,
        accountType: [AccountType.COMPANY],
        defaultExpiresInDays: 14,
        period: ["CALENDAR_YEAR", "FINANCIAL_YEAR", "CUSTOM"],
      },
    ];
  }

  for(documentType: DocumentType): IDocumentProperties | null {
    const properties = this.getListOfDocumentProperties().find(
      (properties: IDocumentProperties): boolean =>
        documentType === properties.type
    );

    return properties || null;
  }

  getDocumentTypesByCategory(
    documentCategory: DocumentCategory
  ): DocumentType[] {
    return this.getListOfDocumentProperties()
      .filter(
        (properties: IDocumentProperties) =>
          properties.category === documentCategory
      )
      .map((properties: IDocumentProperties) => properties.type);
  }

  getDocumentTypesByAccountType(accountType: AccountType): DocumentType[] {
    return this.getListOfDocumentProperties()
      .filter((properties: IDocumentProperties): IDocumentProperties | null => {
        return properties.accountType.includes(accountType) ? properties : null;
      })
      .map((properties: IDocumentProperties) => properties.type);
  }
}
